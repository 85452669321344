import type { OnInit } from '@angular/core';
import { Component, NgZone } from '@angular/core';
import { Auth } from '@freelancer/auth';
import type { ModalConfig } from '@freelancer/ui';
import { ModalService } from '@freelancer/ui';
import type { ModalResult } from '@freelancer/ui/modal';

@Component({
  selector: 'webapp-compat-modals',
  template: ` <fl-modal></fl-modal> `,
})
export class CompatModalsComponent implements OnInit {
  constructor(
    private auth: Auth,
    private ngZone: NgZone,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    const w = window as any;
    w.webappCompatModals = {
      open: (
        componentName: string,
        config: ModalConfig,
        afterClosedCallback?: (result?: ModalResult) => void,
      ) =>
        this.ngZone.run(() =>
          this.modalService
            // The replace is for backwards compatibility, where places opened eg. `AwardModalComponent`.
            // This supports both `AwardModalComponent` and `AwardModal`.
            .open(componentName.replace('Component', '') as any, config)
            .afterClosed()
            .then(result => {
              if (afterClosedCallback) {
                afterClosedCallback(result);
              }
              return result;
            }),
        ),
      // this is used for backwards compatibility reasons to set the credentials from the old stack
      setSession: (userId: string, token: string) =>
        this.ngZone.run(() => {
          this.auth.setSession(userId, token);
        }),
    };
    if (w.webappCompatModalsReadyCallback) {
      w.webappCompatModalsReadyCallback();
    }
  }
}
